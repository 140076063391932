<template>
    <div class="shop-personal">
        <div class="sp-con" v-if="userInfo">
            <a-form :label-col="{span:6}" :wrapper-col="{span:14}">
                <a-form-item label="账号">
                    <span>{{userInfo.mobile}}</span>
                    <span class="upd-pass" @click="show.password = true">修改密码</span>
                </a-form-item>
                <a-form-item label="姓名">
                    <a-input v-model:value="userInfo.name"></a-input>
                </a-form-item>
                <a-form-item label="邮箱">
                    <a-input v-model:value="userInfo.email"></a-input>
                </a-form-item>
                <a-form-item label="头像">
                    <kd-img-select :src="userInfo.pic_url" @change="e=>userInfo.pic_url = e"></kd-img-select>
                </a-form-item>
                <a-form-item :wrapper-col="{offset:6}">
                    <a-space>
                        <a-button type="primary" @click="sureUpdate">确认修改</a-button>
                        <a-button @click="$router.go(-1)">返回</a-button>
                    </a-space>
                </a-form-item>
            </a-form>
        </div> 
        <a-modal v-model:visible="show.password" title="修改密码" width="600px" @ok="savePassWord" @cancel="show.password = false">
			<a-form ref="form" :model="passwordForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="新密码"><a-input v-model:value="passwordForm.password"></a-input></a-form-item>
				<a-form-item label="再次新密码"><a-input v-model:value="passwordForm.re_password"></a-input></a-form-item>
			</a-form>
		</a-modal>
    </div>
</template>
<script>
import userModel from '@/api/user.js'
import authModel from '@/api/saas/auth.js'
import { reactive, toRefs } from 'vue'
export default {
    setup() {
        const _d = reactive({
            userInfo:null,
            show:{
                password:false,
            },
            passwordForm:{
                password:"",
                re_password:""
            },
        })
        userModel.getUserDetail().then(res=>{
            _d.userInfo = res
        })
        function sureUpdate(){
            let data = {
				pic_url: _d.userInfo.pic_url,
				name: _d.userInfo.name,
				mobile: _d.userInfo.mobile,
				email: _d.userInfo.email,
				user_id: _d.userInfo.user_id,
				type: 1,
				rank: 3,
				scene: 'edit',
				is_self: 1
			}
            userModel.addOrEditChildUser(data,()=>{})
        }

        function savePassWord(){
            _d.passwordForm.user_id = _d.userInfo.user_id
            authModel.addOrEditEmployee('password',_d.passwordForm,()=>{
                _d.show.password = false
                _d.passwordForm.re_password = ""
                _d.passwordForm.password = ""
            })
        }
        return{
            ...toRefs(_d),
            sureUpdate,
            savePassWord
        }
    },
}
</script>
<style lang="scss" scoped>
.sp-con{
    background: #fff;
    margin-top: 12px;
    padding: 24px;

    .upd-pass{
        color: #3399FF;
        cursor: pointer;
        margin-left: 12px;
    }
}
</style>